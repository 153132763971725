.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.login-page {
  background-image: url('./images/tennis.jpeg');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login {
  margin-top: 30vmin;
  background: #009560bf;
}

.slide-switch {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  justify-content: space-evenly;
  height: 2rem;

}

.slide-switch .button {
  background-color: #e7ece7;
  color: #f53f82;
  margin-left: 0.5rem;
}

.slide-switch .minus {
  transform: rotate(180deg);
}

.favs-page {
  background-image: url('./images/sanya-favs.png');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100vh;
}

.dogs-page {
  background-image: url('./images/dogs.png');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100vh;
}

.twitter-div {
  background-image: url('./images/twitter.jpeg');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 80vh;
}

.tennis-div {
  background-image: url('./images/tennis.jpeg');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 80vh;
}

.slide-page {
  background-image: url('./images/bg1.png');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.slide-page {
  color: white;
}

.slide-page .is-three-quarters {
  margin-top: 5rem;
}

.slide-page .is-three-quarters p {
  font-size: 1.1rem;
}